import { LabelInterface, MimeType, Source } from '@outmind/types';
import React from 'react';

import { useFeatureFlags, useTranslations } from '../../hooks';
import { AttachmentIcon } from '../../material';
import { useSelector } from '../../store';
import * as logoHelper from '../../util';
import { SanitizedHtmlTypography, SourceLogo } from '..';
import { Bookmark } from '../Bookmark';
import { DocumentLabels } from '../DocumentLabels';
import { DocumentTag } from '../DocumentTag';
import { useStyles } from './styles';

/**
 * Renders the header of the Document from its "mimeType", "source" and "title" property
 */
export const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  accessLink,
  actionButtons,
  bookmarked,
  displayAsAttachment,
  documentId,
  isLocalFile,
  labels,
  mimeType,
  obfuscated,
  shouldDisplayLabels,
  source,
  tag,
  title,
  index,
  withBookmark,
}) => {
  const classes = useStyles();

  const { t } = useTranslations();

  const { data: flags } = useFeatureFlags();

  const isPreviewOpened = useSelector((s) => s.previews.isOpened);

  const titleText = obfuscated ? t('no_access') : title;

  return (
    <div
      className={isPreviewOpened ? classes.headerPreview : classes.header}
      style={{ opacity: obfuscated ? 0.75 : 1, position: 'relative' }}
    >
      <div
        className={
          flags?.withDocumentLabels.enabled &&
          shouldDisplayLabels &&
          !displayAsAttachment &&
          !isPreviewOpened
            ? classes.headerContentContainerWithLabels
            : classes.headerContentContainer
        }
      >
        {displayAsAttachment ? <AttachmentIcon className={classes.attachmentIcon} /> : null}
        {mimeType ? logoHelper.getFileLogo(mimeType, classes.resultElementFileType) : null}
        {!displayAsAttachment && source ? (
          <SourceLogo className={classes.connectorLogo} source={source} />
        ) : null}
        {titleText ? (
          <SanitizedHtmlTypography
            className={classes.title}
            href={accessLink}
            html={titleText}
            id={`document-title-${index ?? documentId}`}
            isLocalFile={isLocalFile}
            variant="h6"
          />
        ) : null}
        {withBookmark && !obfuscated ? (
          <Bookmark bookmarked={bookmarked} documentId={documentId} variant="button" />
        ) : null}
        {actionButtons}
      </div>
      {tag && <DocumentTag tag={tag} />}
      {labels && flags?.withDocumentLabels.enabled && !isPreviewOpened ? (
        <DocumentLabels documentId={documentId} labels={labels} />
      ) : null}
    </div>
  );
};

interface DocumentHeaderProps {
  /**
   * If the `accessLink` prop is defined, the title will be converted to a link pointing to the
   * passed `accessLink`
   */
  accessLink?: string;
  actionButtons?: JSX.Element;
  bookmarked?: boolean;
  displayAsAttachment?: boolean;
  documentId: string;
  index?: string;
  isLocalFile?: boolean;
  labels?: LabelInterface[];
  mimeType?: MimeType;
  obfuscated?: boolean;
  shouldDisplayLabels?: boolean;
  size?: string;
  source?: Source;
  tag?: { color: string; id: string; value: string };
  title?: string;
  withBookmark?: boolean;
}
